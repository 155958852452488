
































import { Vue, Component } from 'vue-property-decorator'
import { chatImportStore } from '@/store'

@Component({
  components: {
    IconInfo: () => import('@/components/UI/icons/IconInfo.vue'),
  },
})
export default class ChatInfo extends Vue {
  get chatName () {
    return chatImportStore.state.chatName
  }
}
